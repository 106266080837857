import MarkerCluster from '@bvt-features/mainmap/cluster/component/MarkerCluster';
import MarkerSumPoi from '@bvt-features/mainmap/cluster/component/MarkerSumPoi';
import React, { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useGRIANA } from '../../hook';
import L from 'leaflet';
import PopupContent from '../../component/PopupContent';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import _ from 'lodash';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';

export default function PoiCollectionsGrianaContainer() {
  const initialMap = useMap();
  const GRIANA = useGRIANA();
  const MAINMAP = useMainMap();
  const PROJECT = useProject();
  const [geojson, setGeojson] = useState([]);
  const [marker, setMarker] = useState([]);
  const [zoom, setZoom] = useState(5);
  const idMap = initialMap.getContainer().id;

  const styleFinder = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'AREA_COLLECTION_GRIANA'
  );

  const poiSearch = _.find(
    MAINMAP.state.layerVisibilityList,
    (v) => v.key === 'POI_SEARCH'
  );

  useEffect(() => {
    MAINMAP.registerLayer({
      key: 'AREA_COLLECTION_GRIANA',
      isActive: true,
      opacity: !poiSearch ? 1 : 0.4,
      title: 'Grid Analysis',
      description: ['POI']
    });
    let grianaZoomHandler = function () {
      setZoom(this.getZoom());
    };
    initialMap.on('zoomend', grianaZoomHandler);
    return () => {
      MAINMAP.removeLayer('AREA_COLLECTION_GRIANA');
      initialMap.removeEventListener('zoomend', grianaZoomHandler);
    };
  }, [poiSearch]);

  useEffect(() => {
    MAINMAP.updateLayer({
      key: 'AREA_COLLECTION_GRIANA',
      isActive: true,
      opacity: !poiSearch ? 1 : 0.4,
      title: 'Grid Analysis',
      description: [PROJECT?.state?.detail?.name, 'POI']
    });
  }, [PROJECT.state.detail, poiSearch]);

  useEffect(() => {
    if (!idMap.includes('right')) {
      const geojsonData = {
        ...GRIANA.state.geojson,
        features: GRIANA.state.geojson?.features?.filter(({ properties }) =>
          GRIANA.state.acceptable.includes(properties.skala_result)
        ),
      };
      setGeojson(geojsonData);
    } else if (idMap.includes('right')) {
      const geojsonData = {
        ...GRIANA.state.geojsonRight,
        features: GRIANA.state.geojsonRight?.features?.filter(
          ({ properties }) =>
            GRIANA.state.acceptableRight.includes(properties.skala_result)
        ),
      };
      setGeojson(geojsonData);
    }
  }, [
    GRIANA.state.status_GET_AREA,
    GRIANA.state.acceptable,
    GRIANA.state.status_GET_AREA_RIGHT,
    GRIANA.state.acceptableRight,
  ]);

  useEffect(() => {
    if (!idMap.includes('right')) {
      const gidCollection = geojson?.features?.map(v => v?.properties?.gid);
      const data = GRIANA.state.poiCollections.map((feature) => {
        const coor = feature.geometry.coordinates.slice(0, 2).reverse();
        const isInside = gidCollection?.includes(feature?.properties?.gid);
        return isInside && {
          position: coor,
          icon: feature.properties.category_icon,
          popupContent: <PopupContent properties={feature.properties} />,
        };
      });
      setZoom(16);
      setMarker(data.filter(v => v));
    } else if (idMap.includes('right')) {
      const gidCollection = geojson?.features?.map(v => v?.properties?.gid);
      const data = GRIANA.state.poiCollectionsRight.map((feature) => {
        const coor = feature.geometry.coordinates.slice(0, 2).reverse();
        const isInside = gidCollection?.includes(feature?.properties?.gid);
        return isInside && {
          position: coor,
          icon: feature.properties.category_icon,
          popupContent: <PopupContent properties={feature.properties} />,
        };
      });
      setZoom(16);
      setMarker(data.filter(v => v));
    }
  }, [GRIANA.state.poiCollections, GRIANA.state.poiCollectionsRight, geojson]);

  const handleClick = (e) => {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = +urlParams.get('project_id');
    const uuid = urlParams.get('process');
    if (project_id && uuid) {
      if (!idMap.includes('right')) {
        GRIANA.setGid(e);
      }
      if (idMap.includes('right')) {
        GRIANA.setGidRight(e);
      }
    }
  };
  function getBounds(value) {
    return L.geoJSON(value).getBounds();
  }

  const poiCollections = () => {
    return (
      <React.Fragment>
        {geojson?.features?.map((feature) => {
          const { gid: gridId, center, sum_poi } = feature.properties;
          if (
            (!idMap.includes('right') &&
              GRIANA.state?.status_GET_POI === 'LOADING' &&
              gridId === GRIANA.state?.gid) ||
            (idMap.includes('right') &&
              GRIANA.state?.status_GET_POI_RIGHT === 'LOADING' &&
              gridId === GRIANA.state?.gid_right)
          ) {
            return (
              <MarkerSumPoi
                gid={gridId}
                isLoading
                key={gridId}
                position={[+center.lat, +center.lng]}
                total={10}
              />
            );
          } else if (
            (!idMap.includes('right') &&
              !GRIANA?.state?.selected_gid.includes(gridId) &&
              zoom > 9) ||
            (idMap.includes('right') &&
              !GRIANA?.state?.selected_gid_right.includes(gridId) &&
              zoom > 9)
          ) {
            return (
              <MarkerSumPoi
                gid={gridId}
                key={gridId}
                onClick={(e) => {
                  initialMap.fitBounds(getBounds(feature), { maxZoom: 16 });
                  handleClick(e);
                }}
                position={[+center.lat, +center.lng]}
                total={sum_poi}
              />
            );
          } else if (
            (!idMap.includes('right') &&
              GRIANA?.state?.gid === gridId &&
              zoom <= 12) ||
            (GRIANA?.state?.selected_gid.includes(gridId) && zoom === 12) ||
            (!idMap.includes('right') &&
              GRIANA?.state?.gid_right === gridId &&
              zoom <= 12) ||
            (GRIANA?.state?.selected_gid_right.includes(gridId) && zoom === 12)
          ) {
            return (
              <MarkerSumPoi
                gid={gridId}
                key={gridId}
                onClick={(e) => {
                  initialMap.fitBounds(getBounds(feature), { maxZoom: 16 });
                  handleClick(e);
                }}
                position={[+center.lat, +center.lng]}
                total={sum_poi}
              />
            );
          }
          return undefined;
        })}
        {zoom > 12 && (
          <MarkerCluster data={marker} key={idMap} opacity={styleFinder?.opacity} />
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {initialMap && styleFinder?.isActive ? poiCollections() : null}
    </React.Fragment>
  );
}
