import { IconChart } from '@bvt-assets/icon/icon-chart';
import { IconTable } from '@bvt-assets/icon/icon-table';
import React, { useEffect, useState } from 'react';
import './PreviewDetailPOIData.scss';
import _ from 'lodash';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

const showType = [
  {
    key: 'table',
    value: 'Table',
    icon: <IconTable />,
    disabled: false,
  },
  {
    key: 'chart',
    value: 'Chart',
    icon: <IconChart />,
    disabled: false,
  },
];


/**
 * @param {Object} props
 * @param {String} props.category
 * @param {React.ReactElement} props.categoryIcon
 * @param {String} props.area
 * @param {String} props.brand
 * @param {String} props.total
 * @param {Array<{key:String|Number,value:Number,total:Number}>} props.data
 */

export const PreviewDetailPOIData = (props) => {
  // const { area, brand, category, categoryIcon, total, data } = props;
  const { total, data } = props;
  const [state, setState] = useState({
    activeType: 'table',
    sortKey: '',
    sortType: '',
    data: data,
  });
  const internalClickType = (e) => {
    setState({
      ...state,
      activeType: e,
    });
  };

  /**
   * @param {Object} props
   * @param {Array<{key:String|Number,value:String,total:Number}>} props.data
   */
  const DataViewTableItem = (props) => {
    const { data } = props;
    return (
      <div className='PreviewDetailPOIData__result__table'>
        {data?.map((v) => {
          return (
            <div
              className='PreviewDetailPOIData__result__table--item--container'
              key={v?.id}
            >
              <div className='PreviewDetailPOIData__result__table--item'>
                <span>{v?.name}</span>
                <span>{v?.count.toLocaleString('id-ID')}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  /**
   * @param {Object} props
   * @param {Array<{key:String|Number,value:String,total:Number}>} props.data
   * @param {Number} props.total
   */
  const DataViewChartItem = (props) => {
    const { data, total } = props;
    return (
      <div className='PreviewDetailPOIData__result__chart'>
        {data?.map((v) => {
          return (
            <div
              className='PreviewDetailPOIData__result__chart--item--container'
              key={v?.id}
            >
              <div className='PreviewDetailPOIData__result__chart--item'>
                <div
                  className='PreviewDetailPOIData__result__chart--item--color'
                  style={{ '--ChartItemWidth': `${(v?.count / total) * 100}%` }}
                />
                <div className='PreviewDetailPOIData__result__chart--item--content'>
                  <span>{v?.name}</span>
                  <span>{v?.count.toLocaleString('id-ID')}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  useEffect(() => {
    setState({
      ...state,
      data: _.orderBy(state?.data, [state?.sortKey], [state?.sortType]),
    });
  }, [state.sortKey, state.sortType]);
  return (
    <div className='PreviewDetailPOIData__container'>
      {/* <Dropdown overlay={MenuItemDropdown(menuItems)} trigger={['click']}>
        <a
          className='PreviewDetailPOIData__dropdown'
          onClick={(e) => e.preventDefault()}
        >
          <Space className='PreviewDetailPOIData__space'>
            <span>
              <img src={categoryIcon} /> {category}
            </span>
            <IconChevron />
          </Space>
        </a>
      </Dropdown> */}
      <div className='PreviewDetailPOIData__type'>
        <div className='PreviewDetailPOIData__type--container'>
          <span className='PreviewDetailPOIData__type--show-as'>Show as</span>
          <div className='PreviewDetailPOIData__type--tab'>
            {showType?.map((v) => {
              return (
                <div
                  className={`PreviewDetailPOIData__type--item ${
                    v?.disabled ? 'disabled' : ''
                  } ${v?.key === state?.activeType ? 'active' : ''}`}
                  key={v?.key}
                  onClick={() => internalClickType(v?.key)}
                >
                  <span>{v?.icon}</span> <span>{v?.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='PreviewDetailPOIData__result'>
        {state?.activeType === 'table' && (
          <DataViewTableItem data={state?.data} />
        )}
        {state?.activeType === 'chart' && (
          <DataViewChartItem data={state?.data} total={total} />
        )}
      </div>
      <div className='PreviewDetailPOIData__result--total'>
        <span>Total POI</span>
        <span>{total}</span>
      </div>
    </div>
  );
};
