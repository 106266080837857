import React from 'react';
import './CardLayerLegend.scss';

/**
 *
 * @param {object} props
 * @param {Array} props.data
 */

export default function CardLayerLegend(props) {
  const { data } = props;

  const formattedNumber = (value) => {
    return value.toLocaleString('id-ID');
  };

  return (
    <div className='feature-datex-telco CardListLayerDatex__container'>
      {[...data]?.map((layer, index) => (
        <div
          className='feature-datex-telco CardListLayerDatex__list'
          key={index}
        >
          <div
            className='feature-datex-telco CardListLayerDatex__list--color'
            style={{
              background: layer.hex,
            }}
          />
          <span className='feature-datex-telco CardListLayerDatex__list--text'>
            {`${layer.name} (${formattedNumber(layer.lower)}-${formattedNumber(layer.upper)})`}
          </span>
        </div>
      ))}
    </div>
  );
}
