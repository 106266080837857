import { IconChart } from '@bvt-assets/icon/icon-chart';
import { IconTable } from '@bvt-assets/icon/icon-table';
import React, { useEffect, useState } from 'react';
import './PreviewDetailThematicData.scss';
import _, { isNumber } from 'lodash';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

const showType = [
  {
    key: 'table',
    value: 'Table',
    icon: <IconTable />,
    disabled: false,
  },
  {
    key: 'chart',
    value: 'Chart',
    icon: <IconChart />,
    disabled: false,
  },
];

/**
 * @param {Object} props
 * @param {String} props.category
 * @param {React.ReactElement} props.categoryIcon
 * @param {String} props.area
 * @param {String} props.brand
 * @param {String} props.total
 * @param {String} props.labelTotal
 * @param {Array<{key:String|Number,value:Number,total:Number}>} props.data
 */

export const PreviewDetailThematicData = (props) => {
  // const { area, brand, category, categoryIcon, total, data } = props;
  const { total, data, labelTotal } = props;
  const [state, setState] = useState({
    activeType: 'table',
    sortKey: '',
    sortType: '',
    data: data,
  });
  const internalClickType = (e) => {
    setState({
      ...state,
      activeType: e,
    });
  };

  useEffect(() => {
    setState({ ...state, data: data?.sort((a, b) => {
      if(b?.count === a?.count) {
        return a?.name.localeCompare(b?.name);
      }
      return b?.count - a?.count;
    }) });
  }, [data]);

  /**
   * @param {Object} props
   * @param {Array<{key:String|Number,value:String,total:Number}>} props.data
   */
  const DataViewTableItem = (props) => {
    const { data } = props;
    return (
      <div className='PreviewDetailThematicData__result__table'>
        {data?.map((v) => {
          return (
            <div
              className='PreviewDetailThematicData__result__table--item--container'
              key={v?.id}
            >
              <div className='PreviewDetailThematicData__result__table--item'>
                <span>{v?.name}</span>
                <span>{v?.count.toLocaleString('id-ID')}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  /**
   * @param {Object} props
   * @param {Array<{key:String|Number,value:String,total:Number}>} props.data
   * @param {Number} props.total
   */
  const DataViewChartItem = (props) => {
    const { data, total } = props;
    return (
      <div className='PreviewDetailThematicData__result__chart'>
        {data?.map((v) => {
          return (
            <div
              className='PreviewDetailThematicData__result__chart--item--container'
              key={v?.id}
            >
              <div className='PreviewDetailThematicData__result__chart--item'>
                <div
                  className='PreviewDetailThematicData__result__chart--item--color'
                  style={{
                    '--ChartItemWidth': `${(v?.count / total) * 100}%`,
                    background: v?.color || '#FF1010',
                    borderColor: v?.color || '#FF1010',
                  }}
                />
                <div className='PreviewDetailThematicData__result__chart--item--content'>
                  <span>{v?.name}</span>
                  <span>{v?.count.toLocaleString('id-ID')} ({`${((v?.count / total) * 100).toFixed(2)}%`})</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  useEffect(() => {
    setState({
      ...state,
      data: _.orderBy(state?.data, [state?.sortKey], [state?.sortType]),
    });
  }, [state.sortKey, state.sortType]);
  return (
    <div className='PreviewDetailThematicData__container'>
      {/* <Dropdown overlay={MenuItemDropdown(menuItems)} trigger={['click']}>
        <a
          className='PreviewDetailThematicData__dropdown'
          onClick={(e) => e.preventDefault()}
        >
          <Space className='PreviewDetailThematicData__space'>
            <span>
              <img src={categoryIcon} /> {category}
            </span>
            <IconChevron />
          </Space>
        </a>
      </Dropdown> */}
      <div className='PreviewDetailThematicData__type'>
        <div className='PreviewDetailThematicData__type--container'>
          <span className='PreviewDetailThematicData__type--show-as'>
            Show as
          </span>
          <div className='PreviewDetailThematicData__type--tab'>
            {showType?.map((v) => {
              if (v.key === 'table' || (v.key === 'chart' && isNumber(total))) {
                return (
                  <div
                    className={`PreviewDetailThematicData__type--item ${
                      v?.disabled ? 'disabled' : ''
                    } ${v?.key === state?.activeType ? 'active' : ''}`}
                    key={v?.key}
                    onClick={() => internalClickType(v?.key)}
                  >
                    <span>{v?.icon}</span> <span>{v?.value}</span>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <div className='PreviewDetailThematicData__result'>
        {state?.activeType === 'table' && (
          <DataViewTableItem data={state?.data} />
        )}
        {state?.activeType === 'chart' && (
          <DataViewChartItem data={state?.data} total={total} />
        )}
      </div>
      {total && isNumber(total) && (
        <div className='PreviewDetailThematicData__result--total'>
          <span>{labelTotal ? labelTotal : 'Total POI'}</span>
          <span>{total}</span>
        </div>
      )}
    </div>
  );
};
