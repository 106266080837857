import React, { useCallback, useEffect, useState } from 'react';
import { BaseSelectedCategoryCard } from '../BaseSelectedCategoryCard';
import { BaseSearchCardDatex } from '../BaseSearchCardDatex';
import './DatextStepTwoThematic.scss';
import { BaseOptionListItemThematic } from '../BaseOptionListItemThematic';
import { getIcon } from '@bvt-shared/utils/iconUtils';

/**
 * @company PT Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

/**
 * @typedef IBaseKeyValueIcon
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {string} icon
 */

/**
 * @typedef IBaseKeyValue
 * @type {Object}
 * @property {string|number} key
 * @property {string} value
 */

/**
 *
 * @param {Object} props
 * @param {Array<IBaseKeyValueIcon>} props.thematicSubcategoryList
 * @param {boolean} props.isLoading
 * @param {(e:string)=>void} props.onSearch
 * @param {(valid:Boolean)=>void} props.onValidOrInvalid
 * @param {()=>void} props.onChange
 * @param {IBaseKeyValueIcon} props.selectedCategories
 * @param {Array<IBaseKeyValue>} props.selectedSubcategories
 */

export const DatextStepTwoThematic = (props) => {
  const {
    isLoading,
    onChange,
    onValidOrInvalid,
    selectedCategories,
    selectedSubcategories,
    thematicSubcategoryList,
  } = props;

  const [state, setState] = useState({
    filter: {
      keyword: '',
      type: undefined,
    },
    subcategories: [],
    selectedSubcategories: undefined,
  });

  const [filteredThematicSubcategoryList, setFilteredThematicSubcategoryList] =
    useState([]);

  useEffect(() => {
    if (!filteredThematicSubcategoryList?.length > 0) {
      setFilteredThematicSubcategoryList(
        thematicSubcategoryList?.sort((a, b) =>
          a?.label?.localeCompare(b?.label)
        )
      );
    }
  }, [thematicSubcategoryList]);

  const internalDebouncedSearchThematicSubcategory = useCallback(
    (e) => {
      setFilteredThematicSubcategoryList(
        thematicSubcategoryList?.filter((v) =>
          v?.label?.toLocaleLowerCase()?.includes(e?.toLocaleLowerCase())
        )
      );
    },
    [filteredThematicSubcategoryList]
  );

  return (
    <div className='DatextStepTwoThematic'>
      <BaseSelectedCategoryCard
        icon={getIcon('thematic', selectedCategories?.icon)}
        title={selectedCategories?.label}
      />
      <BaseSearchCardDatex
        onSearch={(e) => internalDebouncedSearchThematicSubcategory(e)}
        placeholder='Search a specific thematic subcategory'
      />
      <BaseOptionListItemThematic
        isLoading={isLoading}
        listItem={filteredThematicSubcategoryList}
        onChange={(w) => {
          setState({
            ...state,
            selectedSubcategories: {
              key: w?.key,
              value: w?.value,
            },
          });
          onChange && onChange(w);
        }}
        onValidOrInvalid={onValidOrInvalid}
        selectedItem={selectedSubcategories}
        validation={true}
      />
    </div>
  );
};
