import { useProject } from '@bvt-features/mapp/project/hooks/useProject';
import { useStep } from '@bvt-features/mapp/step/hook';
// import DynamicIcon from '@bvt-icons';
import React, { useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useMainMapMenuBar } from '../../hook/useMainMapMenuBar';
import './ProjectName.scss';

export default function ProjectName() {
  const navigate = useNavigate();
  const ctrl = useMainMapMenuBar();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('project_id');
  const processId = searchParams.get('process');
  const STEP = useStep();
  const PROJECT = useProject();

  let forgedParams = '?step=0';
  forgedParams += projectId ? '&project_id=' + projectId : '';
  forgedParams += processId ? '&process=' + processId : '';

  useEffect(() => {
    if (projectId) {
      ctrl.getProject(projectId);
    } else {
      ctrl.removeProjectName();
    }
  }, [projectId, PROJECT?.state?.detail]);

  const openProjectName = (forgedParams) => {
    STEP.setCurrentStep(0);
    navigate(forgedParams);
  };

  const boolTitle = ctrl?.state?.topTitle ? true : false;
  return (
    <div
      className='menu-bar__project-name'
      onClick={() => boolTitle && openProjectName(forgedParams)}
      style={{ cursor: boolTitle && 'pointer' }}
    >
      {/* <DynamicIcon height='1rem' icon='upload' width='1rem' /> */}
      {boolTitle && (
        <>
          <span className='features'>
            {ctrl?.state?.detail?.project_type.split('-')[1]}
          </span>
          <span className='features'>/</span>
          <span className='title'>{ctrl?.state?.topTitle}</span>
        </>
      )}
      {/* <div className='menu-bar__project-name--name'></div> */}
      {/* {boolTitle && (
        <svg
          className='menu-bar__project-name--icon'
          fill='#fff'
          height='0.375rem'
          viewBox='0 0 11 6'
          width='0.75rem'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            color='#fff'
            d='M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z'
            transform='translate(11 6) rotate(180)'
          />
        </svg>
      )} */}
    </div>
  );
}
